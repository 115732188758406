import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  errorText: boolean;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  initialValues: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    ageCheckbox: string;
    termsCheckbox: string;
  };
  schoolData: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailSignUpCallId: any = "";
  validationApiCallId: any = "";
  emailReg: RegExp;
  labelTitle: string = "";
  userProfileGetApiCallId: any;
  userAttr: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      errorText: false,
      showSnackbar: false,
      severity: undefined,
      message: "",
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        ageCheckbox: "",
        termsCheckbox: "",
      },
      schoolData: "",
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleGetUserData();
    // Customizable Area End
  }

  // Customizable Area Start

  parseJwt = (token: string) => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  // @ts-ignore

  handleSignUp = (data: any) => {
    // @ts-ignore

    let params = new URL(document.location).searchParams;
    let userid = params.get("id");

    if (userid?.includes("role_id")) {
      userid = userid?.split("?")[0];
    }

    const header = {
      "Content-Type": configJSON.signUpApiContentType,
    };

    let datas = {
      data: {
        type: "email_account",
        attributes: {
          //@ts-ignore
          id: +userid,
          first_name: data.firstName,
          last_name: data.lastName,
          password: data.password,
          password_confirmation: data.confirmPassword,
          activated: true,
        },
      },
      term_and_condition: data.termsCheckbox,
      age_limit: 17,
    };

    const httpBody = {
      ...datas,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailSignUpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetUserData = () => {
    // @ts-ignore
    let params = new URL(document.location).searchParams;
    let userid = params.get("id");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetUserProfile}${userid}`
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getClassName = (state: any, errors: any, touched: any) => {
    return state.errorText || (!!errors.password && !!touched.password)
      ? ""
      : "pad-bot";
  };
  showError = (state: any, errors: any, touched: any) => {
    return state.errorText || (!!errors.password && !!touched.password);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.errors) {
        const errors = responseJson.errors;
        this.setState({
          showSnackbar: true,
          severity: "error",
          message: errors[0].account || errors[0] || "Something Went Wrong!",
        });
      }
      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.apiEmailSignUpCallId
      ) {
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...responseJson.account?.data.attributes,
            id: responseJson.account?.data.id,
            videoUrl:
              responseJson.site_setting?.data[0]?.attributes?.video_link,
          })
        );
        localStorage.setItem("authToken", responseJson.account?.meta.token);

        if (responseJson.account.data.attributes.role.id === 3) {
          this.props.navigation.navigate("LeadRoleScreen");
        } else {
          this.props.navigation.navigate("TeacherOnboarding");
        }
      } else if (apiRequestCallId === this.userProfileGetApiCallId) {
        if (
          responseJson &&
          !responseJson.errors &&
          responseJson.data &&
          responseJson.data.attributes
        ) {
          //On User Profile Success

          this.userAttr = responseJson.data.attributes;
          if (this.userAttr !== null && this.userAttr !== undefined) {
            if (this.userAttr.activated === true) {
              this.props.navigation.navigate("Login");
            }
            let email = this.userAttr.email;
            let firstName = this.userAttr.first_name;
            let lastName = this.userAttr.last_name;
            this.setState({
              initialValues: {
                email: email,
                firstName: firstName,
                lastName: lastName,
                password: "",
                confirmPassword: "",
                ageCheckbox: "",
                termsCheckbox: "",
              },
              schoolData:
                responseJson.data.attributes.school.data.attributes.name,
            });
          }
        }
      }
    }
    // Customizable Area End
  }
}
