import React from "react";
import { Grid, Button } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
const ProjectDetailsHeader = ({ state, getButtonTitle,updateStepStatus }: any) => {
  return (
    <Grid className="header" item sm={12}>
      <span className="titles">
        {
          state?.projectDetails?.current_project_step_data?.data?.attributes
            ?.step_name
        }
      </span>
      {state?.projectDetails?.project?.project_chapters && (
        <>
          {state?.projectDetails?.current_project_step_data?.status !==
          "not_started" ? (
            <Button
              className="completed-btn"
              endIcon={<DoneIcon style={{ color: "#039855" }} />}
            >
              Completed
            </Button>
          ) : (
            <Button className="finish-btn" endIcon={<DoneIcon />} onClick={() => updateStepStatus()}>
              {getButtonTitle()}
            </Button>
          )}
        </>
      )}
    </Grid>
  );
};

export default ProjectDetailsHeader;
