// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  addObj,
  checkOutCost,
  getAuthToken,
  getLeadMode,
  getUserDetails,
  handleTokenError,
  isTeacher,
} from "../../../components/src/NativeWebRouteWrapper/Utils";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showVideoModal: boolean;
  loading: boolean;
  teachersList: any[];
  IsTeacher: boolean;
  mySchoolDetails: any;
  teachersData: any[];
  schoolCreditHistory: any[];
  page: number;
  perPage: number;
  pagination: any;
  getData: any[];
  dialougContent: boolean;
  upcomingDelivery: any[];
  projectCompletionData: any;
  selectedCheckoutCard: any;
  orderModalOpen: boolean;
  modalTab: string;
  purchasedClassboxes: any;
  totalCost: number;
  newDate: string;
  errorMessage: boolean;
  purchaseDetails: any;
  manageBoxes: any[];
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashBoardWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getAllIvitedUserApiCallId: any;
  getMySchoolDetailApiId: any;
  getMySchoolCreditHistoryApiId: any;
  getUserDetailsApiId: any;
  getDetailsId: any;
  updateStatus: any;
  deleteProjectId: any;
  getUpcomingDeliverys: any;
  projectCompletionId: any;
  returnClassBoxApiCallId: any;
  keepClassBoxApiCallId: any;
  extendClassBoxApiCallId: any;
  requestCreditsApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showVideoModal: true,
      loading: false,
      teachersList: [],
      IsTeacher: isTeacher(),
      mySchoolDetails: {},
      teachersData: [],
      schoolCreditHistory: [],
      page: 1,
      perPage: 8,
      pagination: {},
      getData: [],
      dialougContent: false,
      upcomingDelivery: [],
      projectCompletionData: [],
      selectedCheckoutCard: {},
      orderModalOpen: false,
      modalTab: "keep",
      purchasedClassboxes: [],
      totalCost: 0,
      newDate: "date",
      errorMessage: false,
      purchaseDetails: {},
      manageBoxes: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  closeVideoModal = () => {
    this.setState({
      showVideoModal: false,
    });
    if (getUserDetails().role.name === "Lead") {
      localStorage.setItem("isFirstLead", "false");
    } else {
      localStorage.setItem("isFirstTeacher", "false");
    }
    return true;
  };

  handleStorage = () => {
    this.setState({
      IsTeacher: isTeacher(),
    });
  };

  async componentDidMount() {
    // Customizable Area Start
    window.addEventListener("storage", this.handleStorage);
    this.getTeacherDashboard();
    this.getUpcomingDelivery();
    this.projectCompletion();
    if (getUserDetails().role.name === "Lead") {
      this.getMySchoolDetail();
      this.getMySchoolCreditHistory();
    }
    this.getUserData();

    // Customizable Area End
  }

  async componentWillUnmount() {
    window.removeEventListener("storage", this.handleStorage);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (responseJson) {
        this.setState({
          loading: false,
        });
      }

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getUserDetailsApiId) {
          let user = getUserDetails();
          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              ...user,
              ...responseJson.data.attributes,
              id: responseJson.data.id,
              teacher_mode: getLeadMode(),
            })
          );
          window.dispatchEvent(new Event("storage"));
        }
        if (apiRequestCallId === this.getMySchoolDetailApiId) {
          let teachersData: any[] = [];
          let tempobj = responseJson.teacher_credit_details.map(
            (teacher: any) => {
              teachersData.push({
                name: teacher.name || "",
                earned: teacher.credit_data.data?.attributes.reward || 0,
                allocated: teacher.credit_data.data?.attributes.recieved || 0,
                currentBalance:
                  teacher.credit_data.data?.attributes.current_balance || 0,
                activated: teacher.activated || "",
                teacherId: teacher.id || "",
                image: teacher.image?.url || "",
                teacher_type: teacher.teacher_type,
                spent: teacher.credit_data.data?.attributes.spent || 0,
                unusedCredit: teacher.credit_data.data?.attributes.unused_earned_credits || 0
              });
              return teacher;
            }
          );

          this.setState({
            mySchoolDetails: responseJson,
            teachersData: teachersData,
          });
        } else if (apiRequestCallId === this.getDetailsId) {
          this.setState({ getData: responseJson.data || [] });
        } else if (apiRequestCallId === this.getMySchoolCreditHistoryApiId) {
          this.setState({
            pagination: responseJson.meta.pagination,
            schoolCreditHistory: [
              ...this.state.schoolCreditHistory,
              ...responseJson.history,
            ],
          });
        } else if (apiRequestCallId === this.updateStatus) {
          this.getTeacherDashboard();
        } else if (apiRequestCallId === this.deleteProjectId) {
          this.getTeacherDashboard();
          this.handleDialouge();
        } else if (apiRequestCallId === this.getUpcomingDeliverys) {
          this.setState({ upcomingDelivery: responseJson.data || [] });
        } else if (apiRequestCallId === this.projectCompletionId) {
          let purchasedClassboxes = responseJson.data
            .filter((item: any) => !!item.attributes.order_details)
            .map((item: any) => {
              return {
                ...item.attributes.order_details,
                ...item.attributes,
                ...item,
              };
            });
          let dataProject = responseJson.data.filter((items: any) => items.attributes.order_details !== null && items.attributes.order_details.all_projects_complete)
          this.setState({
            purchasedClassboxes: purchasedClassboxes,
            projectCompletionData: dataProject,
          });
        }
        this.setResponse(apiRequestCallId, responseJson);
      } else if (!!responseJson?.errors && apiRequestCallId === this.keepClassBoxApiCallId ||
        apiRequestCallId === this.extendClassBoxApiCallId) {
        this.setState({
          errorMessage: true,
        });
      }

      // Customizable Area End
    }
  }
  // Customizable Area Start

  setResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.returnClassBoxApiCallId) {
      this.setState({
        orderModalOpen: true,
      });
      this.props.navigation.navigate("StatusBar", {
        id: "3",
        cost: this.state.totalCost,
        tab: this.state.modalTab,
        date: this.state.newDate,
      });
    } else if (apiRequestCallId === this.requestCreditsApiCallId) {
      this.setState({
        orderModalOpen: true,
      });
      this.props.navigation.navigate("StatusBar", {
        id: "1",
        cost: this.state.totalCost,
        tab: this.state.modalTab,
        date: this.state.newDate,
      });
    } else if (
      apiRequestCallId === this.keepClassBoxApiCallId ||
      apiRequestCallId === this.extendClassBoxApiCallId
    ) {
      this.setState({
        orderModalOpen: true,
      });
      this.props.navigation.navigate("StatusBar", {
        id: "2",
        cost: this.state.totalCost,
        tab: this.state.modalTab,
        date: this.state.newDate,
      });
    }
  };

  getTeacherDashboard = () => {
    // Customizable Area Start
    const token = getAuthToken();

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDetialsTeacher
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  getMySchoolDetail = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMySchoolDetailApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMySchoolDetailApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fetchMoreData = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => this.getMySchoolCreditHistory()
    );
  };

  getMySchoolCreditHistory = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    // let schoolId = getUserDetails().school.data.attributes.school_credits.data[0].id
    let schoolId =
      getUserDetails().school.data?.id ||
      getUserDetails().school.data.attributes.school_credits.data[0].id;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMySchoolCreditHistoryApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMySchoolCreditHistoryApiEndPoint}${schoolId}&page=${this.state.page}&per_page=${this.state.perPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserData = () => {
    const header = {
      "Content-Type": configJSON.getUserDetailsApiContentType,
    };

    let user = getUserDetails();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDetailsAPiEndPoint}${user.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserDetailsAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  showTitle = (data: [], key: boolean) => {
    return data.find((item: any) => item.attributes.is_teaching === key);
  };

  startStopProject = (string: string, item: any) => {
    const token = getAuthToken();
    let payload;
    if (string === "Stop teaching") {
      payload = {
        is_teaching: false,
      };
    } else if (string === "Start teaching") {
      payload = {
        is_teaching: true,
      };
    }
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateStatus = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDetialsTeacher}/${item.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putUserDetailsAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  deleteProject = (item: any) => {
    const token = getAuthToken();

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProjectId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDetialsTeacher}/${item.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteUserDetailsAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  handleDialouge = () => {
    this.setState({ dialougContent: !this.state.dialougContent });
  };

  getUpcomingDelivery = () => {
    // Customizable Area Start
    const token = getAuthToken();

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUpcomingDeliverys = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upcomingDeliveriesApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };
  projectCompletion = () => {
    // Customizable Area Start
    const token = getAuthToken();

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectCompletionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectCompletionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };
  handleCheckOut = (item: any) => {
    this.setState({
      selectedCheckoutCard: checkOutCost(item),
      orderModalOpen: true,
    });
    return true;
  };
  handleOrderModal = () => {
    this.setState({
      orderModalOpen: !this.state.orderModalOpen,
    });
    return true;
  };
  handleModalTabChange = (value: string) => {
    this.setState({
      modalTab: value,
      errorMessage: false
    });
    return true;
  };

  handleReturnClassBox = (id: number | string) => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.returnClassBoxApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `classbox/order_details/${id}/manage_checkout`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            status: "return_requested",
          },
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putUserDetailsAPiMethod
    );

    this.setState({
      orderModalOpen: false,
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleKeepClassBox = (id: number | string, cost: number) => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keepClassBoxApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `classbox/order_details/${id}/manage_checkout`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            status: "keep_requested",
            cost: cost,
            keep_request_cost:cost
          },
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putUserDetailsAPiMethod
    );
    this.setState({
      loading: true,
      totalCost: cost,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleExtendClassBox = (
    id: number | string,
    cost: number,
    end_date: string,
    start_date: string,
    quantity:any
  ) => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.extendClassBoxApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `classbox/order_details/${id}/manage_checkout`
    );

    let date = new Date(
      new Date(end_date).setMonth(new Date(end_date).getMonth() + 1)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            status: "extended",
            cost: cost * quantity,
            start_date: new Date(end_date),
            end_date: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
          },
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putUserDetailsAPiMethod
    );

    this.setState({
      loading: true,
      totalCost: cost,
      newDate: `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  requestCreditsForTraining = (
    request_type: string,
    classbox_id: number | string,
    CreditsToOwnWithCheckoutCost : any

  ) => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestCreditsApiCallId = requestMessage.messageId;
    let obj = {
      request_type: request_type,
      classbox_id: classbox_id,
      order_status_request :  "checkout",
    }
    addObj(request_type,obj,CreditsToOwnWithCheckoutCost)
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_trainings/teacher_requests`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(obj)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      orderModalOpen: false,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
// Customizable Area End
