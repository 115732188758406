import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import ConfirmDialog from "./ComformDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      padding: "8px 14px",
      height: "112px",
      background: "#FFFFFF",
    },
    blue: {
      fontStyle: "normal",
      fontWeight: 350,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#007DC6",
    },
    red: {
      fontStyle: "normal",
      fontWeight: 350,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#D92D20",
    },
  })
);

function PopoverComponent({ anchorEl, handleClose }: any) {
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleOkClick = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className={classes.paper}>
          {true ? (
            <span className={classes.blue} onClick={handleClickOpenDialog}>
              Make Classbox Lead
            </span>
          ) : (
            <span className={classes.blue}>Resend Invite</span>
          )}
          <span className={classes.red}>Delete User</span>
        </div>
      </Popover>
      <ConfirmDialog
        open={openConfirmDialog}
        onOkText="Yes, I’m sure"
        onCancleText="Cancle"
        handleClose={handleCloseDialog}
        title="Are you sure?"
        content="You are Reassigning the Classbox Leader for your school from you to this user."
        onOk={handleOkClick}
        onCancle={handleCloseDialog}
        warningText="You will immediately lose admin priveleges from your account, are you sure you want to do this?"
      />
    </>
  );
}

export default PopoverComponent;
