// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getUserDetails,
  handleTokenError,
  handleUncompleteSteps,
  newReward,
  setLocalStorageCurrentBalance,
} from "../../../components/src/NativeWebRouteWrapper/Utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  severity: Color;
  message: string;
  loading: boolean;
  showSnackbar: boolean;
  tab: number;
  projectDetails: any;
  overview: boolean;
  previewMode: string;
  htmlContent: string;
  standardTab: string;
  selectedPrevieStepId:any;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class ProjectDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTrainingDataApiId: any;
  updateStepStatusApiCallId: any;
  getStepsDataWithProjectDataApiId: any;
  getProjectsDataApiId: any;
  getProjectsDataPreviewApiId: any;
  plusPlan: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      tab: 0,
      projectDetails: {},
      overview: true,
      previewMode: "default",
      htmlContent: "<></>",
      standardTab: "0",
      selectedPrevieStepId: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.setState({
      previewMode: this.props.navigation.getParam("mode"),
    });
    if (this.props.navigation.getParam("mode") === "preview") {
      this.getProjectsDataPreview();
    } else {
      this.getProjectsData();
    }
    // Customizable Area End
  }

  async componentWillUnmount() {
    // Customizable Area Start
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    if (this.state.projectDetails !== prevState.projectDetails) {
      if (this.state.projectDetails?.steps?.data?.length > 0) {
        this.setState({
          selectedPrevieStepId: this.state.projectDetails?.steps?.data[0]?.id,
        });
      }
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      this.setState({
        loading: false,
      });
      if (!responseJson.errors) {
        if (
          apiRequestCallId === this.getProjectsDataApiId ||
          apiRequestCallId === this.getStepsDataWithProjectDataApiId
        ) {
          this.setState({
            projectDetails: responseJson,
          });
        } else if (apiRequestCallId === this.updateStepStatusApiCallId) {
          this.getNextStepId(!!this.state.projectDetails.project.project_chapters.find((item:any) => item.status !== "completed"));
          this.setState({
            projectDetails: responseJson,
          });
        } else if (apiRequestCallId === this.getProjectsDataPreviewApiId) {
          this.setState({
            projectDetails: responseJson,
            htmlContent:
              responseJson.steps.data?.[0].attributes.content || "<></>",
          });
        } else if (apiRequestCallId === this.plusPlan) {
          console.log("added to plan")
        }
      }
      // Customizable Area End
    }
  }

  setContent = (content: string, id: any) => {
    this.setState({
      htmlContent: content,
      overview: false,
      selectedPrevieStepId: id
    });
  };

  setStandardTab = (tab: string) => {
    this.setState({ standardTab: tab });
  };

  toggleOverview = (value: boolean) => {
    this.setState({
      overview: value,
    });
  };

  setTab = (tab: number) => {
    this.setState({
      tab: tab,
    });
    return true;
  };

  getButtonTitle = () => {
    if (
      this.state.projectDetails?.project?.project_chapters[
        this.state.projectDetails.project.project_chapters.length - 1
      ]?.project_chapter_id ===
      this.state.projectDetails?.current_project_step_data?.project_chapter_id
    ) {
      return "Finish Project";
    } else {
      return "Finish Step";
    }
  };

  getPreviousStepId = () => {
    if (
      this.state.projectDetails?.project?.project_chapters[0]
        ?.project_chapter_id ===
      this.state.projectDetails?.current_project_step_data?.project_chapter_id
    ) {
      return;
    } else {
      let indexOfNextStep = 0;
      this.state.projectDetails?.project?.project_chapters.map(
        (item: any, index: number) => {
          if (
            item.project_chapter_id ===
            this.state.projectDetails.current_project_step_data
              .project_chapter_id
          ) {
            indexOfNextStep = index - 1;
          }
          return item;
        }
      );
      this.getStepsDataWithTraining(
        this.state.projectDetails?.project?.project_chapters[indexOfNextStep]
          .project_chapter_id
      );
      this.setState({
        overview: false,
      });
    }
  };

  getNextStepId = (x: boolean) => {
    if (
      this.state.projectDetails?.project?.project_chapters[
        this.state.projectDetails?.project?.project_chapters.length - 1
      ]?.project_chapter_id ===
      this.state.projectDetails?.current_project_step_data?.project_chapter_id
    ) {
      handleUncompleteSteps(this.state, this.props.navigation, this.getStepsDataWithTraining, x)
    } else {
      let indexOfNextStep = 0;
      this.state.projectDetails?.project?.project_chapters.map(
        (item: any, index: number) => {
          if (
            item?.project_chapter_id ===
            this.state.projectDetails?.current_project_step_data
              ?.project_chapter_id
          ) {
            indexOfNextStep = index + 1;
          }
          return item;
        }
      );
      this.getStepsDataWithTraining(
        this.state.projectDetails?.project?.project_chapters[indexOfNextStep]
          .project_chapter_id
      );
    }
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  showPrevious = () => {
    return (
      this.state?.projectDetails?.project?.project_chapters[0]
        .project_chapter_id !==
      this.state.projectDetails.current_project_step_data.project_chapter_id
    );
  };

  getStepsDataWithTraining = (id: string | number) => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStepsDataWithProjectDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/project_steps/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    this.setState({
      loading: true,
      overview: false,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateStepStatus = () => {
    let token = getAuthToken();
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateStepStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/project_steps/${this.state.projectDetails.current_project_step_data.project_chapter_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        status: "completed",
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getProjectsData = () => {
    let token = getAuthToken();
    let x = this.props.navigation?.getParam("id");
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectsDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/projects/${x}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getProjectsDataPreview = () => {
    let token = getAuthToken();
    let x = this.props.navigation?.getParam("id");
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectsDataPreviewApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/projects/${x}/?screen=preview`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addProjectPlan = () => {
    let token = getAuthToken();
    const payload = {
      account_project: this.state.projectDetails?.project?.account_project,
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.plusPlan = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.plusPlanEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}
// Customizable Area End

// Customizable Area End
