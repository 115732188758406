export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const training = require("../assets/training.png");
export const sideBar = require("../assets/sideBar.svg");
export const sideBarHeader = require("../assets/sidebar-header.svg");
export const completed = require("../assets/completed.svg");
export const remaining = require("../assets/remaining.svg");
export const right = require("../assets/right.svg");
export const download = require("../assets/download.svg");
export const files = require("../assets/files.svg");
export const rocket = require("../assets/rocket.svg");
export const share = require("../assets/share.svg");
export const gradeIcon = require("../assets/grades.svg");
export const durationIcon = require("../assets/duration.svg");
export const subject = require("../assets/subject.svg");
export const award = require("../assets/award.svg");
export const cardOneback = require("../assets/cardoneback.png");
export const cardtwoback = require("../assets/cardtwoback.png");
export const tworingsIcon = require("../assets/tworings.svg");
export const logo = require("../assets/logo.svg");
export const rightWhite = require("../assets/rightWhite.svg");
export const emailIcon = require("../assets/emailIcon.svg");
export const twitterIcon = require("../assets/twitterIcon.svg");
