// Customizable Area Start
import React from "react";
import { Grid, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
export const configJSON = require("./config");
import MySchoolController, { Props } from "./MySchoolController";
import { inviteUser } from "./assets";
import "../assets/myschool.scss";
import TableComponent from "./components/TableComponent";
import InviteTeacherModal from "./components/InviteTeacherModal";
import TeacherDetailsDrawer from "./components/TeacherDetailsDrawer";
import ReclaimCreditModal from "./components/ReclaimCreditModal";
import AllocateCreditModal from "./components/AllocateCreditModal";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import {
  getCommaSeperator,
  getCredits,
  getSchoolCurrBaklance,
  getTeachers,
} from "../../../components/src/NativeWebRouteWrapper/Utils";

export default class MySchool extends MySchoolController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Grid container className="myschool-container">
        <Grid item container sm={12} spacing={3} className="card-container">
          <Grid item sm={4} md={4} xs={12}>
            <div className="card">
              <span className="title">
                {getCommaSeperator(
                  this.state.mySchoolDetails.teacher_count || 0
                )}
              </span>
              <span className="desc">
                {getTeachers(this.state.mySchoolDetails?.teacher_count)}
              </span>
            </div>
          </Grid>
          <Grid item sm={4} md={4} xs={12}>
            <div className="card">
              <span className="title">
              {getSchoolCurrBaklance(this.state)}
              </span>
              <span className="desc">
                Unallocated{" "}
                {getCredits(
                  this.state.mySchoolDetails?.school_credit_details?.data
                    ?.attributes?.current_balance
                )}
              </span>
            </div>
          </Grid>
          <Grid item sm={4} md={4} xs={12}>
            <div className="card">
              <span className="title">
                {getCommaSeperator(
                  this.state.mySchoolDetails?.school_credit_details?.data
                    ?.attributes?.current_balance || 0
                )}
              </span>
              <span className="desc">Current School Balance</span>
            </div>
          </Grid>
        </Grid>
        <Grid item container sm={12} spacing={3} className="table-container">
          <Grid sm={12} item className="table-header">
            <span className="title">{getTeachers(this.state.mySchoolDetails.teacher_count)}</span>
            <Button className="btn" onClick={this.handleDialog}>
              Invite Teachers &nbsp;&nbsp;
              <img src={inviteUser} alt="icon" />
            </Button>
          </Grid>
          <Grid item sm={12}>
            <TableComponent
              rows={this.state.teachersData}
              handleDrawer={this.handleDrawer}
              setDrawerData={this.setDrawerData}
            />
          </Grid>
        </Grid>
        <InviteTeacherModal
          handleDialog={this.handleDialog}
          onAddTeacher={this.onAddTeacher}
          state={this.state}
        />
        {this.state.isDrawerOpen && (
          <TeacherDetailsDrawer
            isDrawerOpen={this.state.isDrawerOpen}
            handleDrawer={this.handleDrawer}
            handleReclaimModal={this.handleReclaimModal}
            handleAddCreditModal={this.handleAddCreditModal}
            state={this.state}
            fetchMoreData={this.fetchMoreData}
          />
        )}
        {this.state.openReclaimModal && (
          <ReclaimCreditModal
            state={this.state}
            handleReclaimModal={this.handleReclaimModal}
            onClickReclaimCredit={this.onClickReclaimCredit}
          />
        )}
        {this.state.openAddCreditModal && (
          <AllocateCreditModal
            state={this.state}
            handleAddCreditModal={this.handleAddCreditModal}
            onAddCredit={this.onAddCredit}
          />
        )}
        <LoaderComponent open={this.state.loading} />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
      </Grid>
    );
  }
}
// Customizable Area End
