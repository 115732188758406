import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Avatar,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PopoverComponent from "./PopoverComponent";
import "../../assets/table-component.scss";
import { getCommaSeperator } from "../../../../components/src/NativeWebRouteWrapper/Utils";

interface Data {
  name: string;
  earned: number;
  allocated: number;
  currentBalance: number;
  activated: boolean;
  image: string;
  teacherId: string;
}

interface Row {
  name: string;
  earned: number;
  allocated: number;
  currentBalance: number;
  activated: boolean;
  image: string;
  teacherId: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "earned",
    numeric: true,
    disablePadding: false,
    label: "Earned Credits",
  },
  {
    id: "allocated",
    numeric: true,
    disablePadding: false,
    label: "Allocated Credits",
  },
  {
    id: "currentBalance",
    numeric: true,
    disablePadding: false,
    label: "Current Balance",
  },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span className={classes.headTitle}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      border: "1px solid #EAECF0",
      borderRadius: "8px",
    },
    table: {
      width: "100%",
    },
    tableHeader: {
      background: "#F9FAFB",
      borderWidth: "0px 1px 1px 0px",
      borderStyle: "solid",
      borderColor: "#EAECF0",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    headTitle: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#344054",
    },
    fieldValue: {
      fontStyle: "normal",
      fontWeight: 325,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#344054",
    },
    bluessh: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#007DC6",
    },
  })
);

function TableComponent({ rows, handleDrawer, setDrawerData }: any) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    setDrawerData(row);
    handleDrawer();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickAction = (event: React.MouseEvent<HTMLElement>) => {
    // setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <TableContainer className="table-component-container">
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.teacherId}
                    className="pointer"
                  >
                    <TableCell
                      align="left"
                      className={classes.fieldValue}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="normal"
                      onClick={(event) => handleClick(event, row)}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Avatar
                        alt=""
                        //@ts-ignore
                        src={row.image}
                      />
                      &nbsp;&nbsp;<span>{row.name}</span>&nbsp;&nbsp;{!row.activated && <div className="tag-pending">Pending</div>}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.fieldValue}
                      onClick={(event) => handleClick(event, row)}
                    >
                      {getCommaSeperator(row.earned)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.fieldValue}
                      onClick={(event) => handleClick(event, row)}
                    >
                      {getCommaSeperator(row.allocated)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.fieldValue}
                      onClick={(event) => handleClick(event, row)}
                    >
                      {getCommaSeperator(row.currentBalance)}
                    </TableCell>
                    <TableCell align="right" className={classes.fieldValue}>
                      <PopoverComponent
                        handleClose={handlePopoverClose}
                        anchorEl={anchorEl}
                      />
                      <div onClick={handleClickAction}>
                        <MoreVertIcon color="disabled" />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default TableComponent;
