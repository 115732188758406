// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getLeadMode,
  getUserDetails,
  handleTokenError,
  isLead,
  isTeacher,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  profileImage: string;
  showPassword0: boolean;
  showPassword1: boolean;
  showPassword2: boolean;
  initialValues: {
    prefix: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    gradeTaught: string;
  };
  initialValuesChangePassword: {
    password: string;
    newPassword: string;
    repeatePassword: string;
  };
  initialValuesSchoolDetails: {
    schoolName: string;
    classboxLead: string;
    schoolAddress: string;
  };
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  standardsData: any[];
  page: number;
  pagination: {
    per_page: number;
    current_page: number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: number;
    total_count: number;
  };
  unSelectedId: any;
  standardSetsNumber:number
  // Customizable Area End
}

interface SS {
  id: any;
}
export default class ProfileWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserDetailsApiId: any;
  updateUSerDetailsApiId: any;
  updatePasswordApiId: any;
  updateImage: any;
  getStandardsDataApiId: any;
  selectStandardApiCallId: any;
  unSelectStandardApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      profileImage: "",
      showPassword0: false,
      showPassword1: false,
      showPassword2: false,
      initialValues: {
        prefix: "",
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        gradeTaught: "",
      },
      initialValuesChangePassword: {
        password: "",
        newPassword: "",
        repeatePassword: "",
      },
      initialValuesSchoolDetails: {
        schoolName: "",
        classboxLead: "",
        schoolAddress: "",
      },
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      standardsData: [],
      page: 1,
      pagination: {
        per_page: 5,
        current_page: 1,
        next_page: null,
        prev_page: null,
        total_pages: 0,
        total_count: 0,
      },
      unSelectedId: 0,
      standardSetsNumber:0
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getStandardsData();
    this.getUserData();
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (responseJson) {
        this.setState({
          loading: false,
        });
      }

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getUserDetailsApiId) {
          let userData = responseJson?.data?.attributes;
          let user = getUserDetails();
          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              ...user,
              ...responseJson?.data?.attributes,
              id: responseJson?.data.id,
              teacher_mode: getLeadMode(),
            })
          );
          window.dispatchEvent(new Event("storage"));
          this.setState({
            profileImage: userData?.image?.url || "",
            initialValues: {
              prefix: userData.prefix || "",
              email: userData.email || "",
              firstName: userData.first_name || "",
              lastName: userData.last_name || "",
              role: isTeacher()
                ? userData.teacher_type
                : userData.lead_type || "",
              gradeTaught: userData.grade || "",
            },
            initialValuesSchoolDetails: {
              schoolName: userData.school.data.attributes.name,
              classboxLead: !isLead()
                ? userData.invited_by
                : userData.first_name + " " + userData.last_name,
              schoolAddress: userData.school.data.attributes.address,
            },
          });
        }
        if (apiRequestCallId === this.updateUSerDetailsApiId) {
          if (responseJson.data.attributes) {
            this.setState({
              showSnackbar: true,
              severity: "success",
              message: "Profile updated successfully",
            });
            this.getUserData();
          }
        }
        if (apiRequestCallId === this.updatePasswordApiId) {
          if (responseJson.message) {
            this.setState({
              showSnackbar: true,
              severity: "success",
              message: responseJson.message || "Password change successfully",
            });
            this.getUserData();
          }
        }
        if (apiRequestCallId === this.updateImage) {
          if (responseJson.data.attributes) {
            this.setState({
              showSnackbar: true,
              severity: "success",
              message: "Profile updated successfully",
            });
            this.getUserData();
          }
        }
        if (apiRequestCallId === this.getStandardsDataApiId) {
          this.setState({
            standardsData: [
              ...this.state.standardsData,
              ...responseJson.data,
            ].sort(this.customSort),
            pagination: responseJson.meta.pagination,
            standardSetsNumber: responseJson.meta.account_standards_count
          });
        } else if (apiRequestCallId === this.unSelectStandardApiCallId) {
          this.setState({
            standardsData: this.state.standardsData.map((item: any) => {
              if (item.id == this.state.unSelectedId) {
                return {
                  ...item,
                  attributes: {
                    ...item.attributes,
                    account_added: null,
                  },
                };
              } else {
                return item;
              }
            }).sort(this.customSort),
          });
        } else if (apiRequestCallId === this.selectStandardApiCallId) {
          this.setState({
            standardsData: this.state.standardsData.map((item: any) => {
              if (item.id == responseJson.data.attributes.standard_set_id) {
                return {
                  ...item,
                  attributes: {
                    ...item.attributes,
                    account_added: {
                      added: true,
                      account_standard_id: +responseJson.data.id,
                    },
                  },
                };
              } else {
                return item;
              }
            }).sort(this.customSort),
          });
        }
      } else {
        const errors = responseJson?.errors;
        this.setState({
          showSnackbar: true,
          severity: "error",
          message: errors || "Something Went wrong",
        });
      }
    }
    // Customizable Area End
  }

  getUserData = () => {
    const header = {
      "Content-Type": configJSON.getUserDetailsApiContentType,
    };

    let user = getUserDetails();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDetailsAPiEndPoint}${user.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserDetailsAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateUserData = (data: any) => {
    let user = getUserDetails();
    let token = getAuthToken();
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUSerDetailsApiId = requestMessage.messageId;

    if (isTeacher()) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateTeacherDetailsAPiEndPoint}${user.id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateUserDetailsAPiEndPoint}${user.id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateUserDetailsAPiMethod
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChangePassword = (data: any) => {
    let user = getUserDetails();
    let token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": configJSON.getUserDetailsApiContentType,
    };

    let payload = {
      current_password: data.password,
      new_password: data.newPassword,
      password_confirmation: data.repeatePassword,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePasswordApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.changePasswordApi}${user.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.changePasswordMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubmit = (values: any) => {
    let formData = new FormData();
    formData.append("first_name", values.firstName);
    formData.append("last_name", values.lastName);
    isTeacher()
      ? formData.append("teacher_type", values.role)
      : formData.append("lead_type", values.role);
    formData.append("prefix", values.prefix);
    formData.append("grade", values.gradeTaught);

    this.updateUserData(formData);
    return true;
  };

  handleClickShowPassword = (value: string) => {
    switch (value) {
      case "password0":
        this.setState({
          showPassword0: !this.state.showPassword0,
        });
        return;
      case "password1":
        this.setState({
          showPassword1: !this.state.showPassword1,
        });
        return;
      case "password2":
        this.setState({
          showPassword2: !this.state.showPassword2,
        });
        return;
      default:
        return;
    }
  };

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
    return true;
  };

  onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.setState({ profileImage: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      let user = getUserDetails();
      let token = getAuthToken();
      const header = {
        token: token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateImage = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateImageField}${user.id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      this.setState({
        loading: true,
      });

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
    return true;
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  getStandardsData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStandardsDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStandardsDataApiEndpoint}?per=${6}&page=${this.state.page
      }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  selectStandard = (standardID: string | number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const httpBody = {
      data: {
        attributes: {
          standard_set_id: +standardID,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.selectStandardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setStandardsDataApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  unSetStandard = (standardID: string | number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unSelectStandardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unSetStandardsDataApiEndpoint + standardID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  updateStateOfStandard = (item: any) => {
    if (item.attributes.account_added) {
      this.setState({
        unSelectedId: item.id,
      });
      this.unSetStandard(item.attributes.account_added.account_standard_id);
    } else {
      this.selectStandard(item.id);
    }
    this.getStandardsData();
  };

  fetchMoreData = () => {
    if (this.state.page <= this.state.pagination.total_pages) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => this.getStandardsData()
      );
    }
  };
  customSort(a: any, b: any) {
    // Check if "account_added" is present and set to true for both objects
    const isAccountAddedA =
      a.attributes.account_added && a.attributes.account_added.added;
    const isAccountAddedB =
      b.attributes.account_added && b.attributes.account_added.added;

    // Sort based on "account_added" status
    if (isAccountAddedA && !isAccountAddedB) {
      return -1; // a comes first
    } else if (!isAccountAddedA && isAccountAddedB) {
      return 1; // b comes first
    } else {
      return 0; // leave the order unchanged
    }
  }
  getStatus = (item: any) => {
    return item.attributes.account_added ? true : false;
  };
}

// Customizable Area End
