import React from "react";
import { Grid, Divider, Button } from "@material-ui/core";
import {
  award,
  cardOneback,
  download,
  durationIcon,
  files,
  gradeIcon,
  rightWhite,
  rocket,
  share,
  subject,
  tworingsIcon,
} from "../assets";
import { creatorCard2 } from "../../../ProjectTemplates/src/assets";
import {
  addS,
  getCommaSeperator,
  getGradeCombo,
  getMinutes,
  getTrimTitle,
  getTwitterURL,
  oneToMany,
} from "../../../../components/src/NativeWebRouteWrapper/Utils";
import StandardsAccordian from "./StandardsAccordian";
const ProjectOverview = ({
  navigation,
  addProjectPlan,
  state,
  setTab,
  data,
  toggleOverview,
}: any) => {
  let {
    title,
    grade,
    subject_tags,
    credit_award,
    duration,
    short_description,
    hero_image,
    creator_name,
    related_training,
    related_classboxes,
    standard_sets
  } = data;
  return (
    <Grid item sm={9} container className="main1">
      <div className="header-one">
        <img src={hero_image?.url} alt="img" className="back-image" />
        <div className="cover-div"></div>
        <h1 className="title">{getTrimTitle(40, title)}</h1>
        <div className="componet-abs">
          <Button
            className="start-tech-btn pg-sk1"
            onClick={() => toggleOverview(false)}
          >
            {state.previewMode === "default"
              ? "Start teaching"
              : "Preview Steps"}{" "}
            &nbsp;
            <img src={rightWhite} alt="icon" />
          </Button>
          <div className="btn-container">
            {!related_classboxes?.title && (
              <>
                <Button
                  className="cmn-btn"
                  id="add-to-plan"
                  onClick={addProjectPlan}
                >
                  <img src={rocket} alt="icon" />
                  &nbsp;<span className="project-card-text">Add To Plan</span>
                </Button>
                <a
                  href={`mailto:feedback@classbox.com`}
                  target="_blank"
                  className="cmn-btn"
                >
                  <img src={files} alt="email" />
                  &nbsp;<span className="project-card-text">Feedback</span>
                </a>
                <a
                  href={data?.pdf_file?.url}
                  target="_blank"
                  className="cmn-btn"
                >
                  <Button className="cmn-btn" style={{ marginRight: "0px" }}>
                    <img src={download} alt="icon" />
                    &nbsp;<span className="project-card-text">Download</span>
                  </Button>
                </a>
              </>
            )}
            <a
              href={getTwitterURL()}
              target="_blank"
              className="cmn-btn"
            >
              <img src={share} alt="icon" />
              &nbsp;<span className="project-card-text">Share</span>
            </a>
            {related_classboxes?.title && (
              <Button
                className="cmn-btn1 pg-sk2"
                onClick={() =>
                  navigation.navigate("PurchaseClassBox", {
                    ids: related_classboxes.id,
                  })
                }
              >
                <img src={tworingsIcon} alt="icon" />
                &nbsp;&nbsp;Order Classbox
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="content">
        <div className="left">
          <div className="title1">Project Details</div>
          <div className="content-card">
            <div className="tabs-header">
              <div
                id="tab0btn"
                className={
                  state.tab === 0
                    ? "tab-title-container pointer"
                    : "tab-title-container-default pointer"
                }
                onClick={() => setTab(0)}
              >
                <span className="txt-title">&nbsp;Overview</span>
              </div>
              <div
                id="tab1btn"
                className={
                  state.tab === 1
                    ? "tab-title-container pointer"
                    : "tab-title-container-default pointer"
                }
                onClick={() => setTab(1)}
              >
                <span className="txt-title">&nbsp;Standards</span>
              </div>
              <div
                id="tab2btn"
                className={
                  state.tab === 2
                    ? "tab-title-container pointer"
                    : "tab-title-container-default pointer"
                }
                onClick={() => setTab(2)}
              >
                <span className="txt-title">&nbsp;Set Up Guide</span>
              </div>
            </div>
            <div className="tabs-content">
              {state.tab === 0 && (
                <>
                  <h1 className="tab-con-title">Description</h1>
                  <span className="tab-con-des">{short_description}</span>
                  <Grid container className="card-container" spacing={1}>
                    <Grid item sm={6} className="card-grid">
                      <div className="card">
                        <img src={gradeIcon} alt="img" />
                        <span className="txt-title">Grades</span>
                        <span className="txt-des">
                          {getGradeCombo(grade)}
                        </span>
                      </div>
                    </Grid>
                    <Grid item sm={6} className="card-grid">
                      <div className="card">
                        <img src={durationIcon} alt="img" />
                        <span className="txt-title">Duration</span>
                        <span className="txt-des">{duration} {getMinutes(duration)}</span>
                      </div>
                    </Grid>
                    <Grid item sm={6} className="card-grid">
                      <div className="card">
                        <img src={subject} alt="img" />
                        <span className="txt-title">Subject{oneToMany(subject_tags)}</span>
                        <span className="txt-des">
                          {subject_tags.join(", ")}
                        </span>
                      </div>
                    </Grid>
                    <Grid item sm={6} className="card-grid">
                      <div className="card">
                        <img src={award} alt="img" />
                        <span className="txt-title">Credit Award{addS(credit_award)}</span>
                        <span className="txt-des">
                          {getCommaSeperator(credit_award)}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
              {state.tab === 2 && (
                <>
                  <h1 className="tab-con-title">Set Up Guide</h1>
                  <span className="tab-con-des-1">
                    Coming Soon
                  </span>
                </>
              )}
              {state.tab === 1 && <StandardsAccordian standard_sets={standard_sets}/>}
            </div>
          </div>
        </div>
        <Divider orientation="vertical" flexItem className="divider-grey" />
        <div className="right">
          <div className="title1">Resources</div>
          {related_classboxes?.title && (
            <div className="card">
              <img src={cardOneback} alt="img" className="card-back-image" />
              <div className="card-content">
                <div className="card-details">
                  <h1 className="title-card">Classbox:</h1>
                  <span className="desc-card">{related_classboxes?.title}</span>
                </div>
                <img
                  src={related_classboxes?.emoji_url.url}
                  className="icon-card-one"
                  alt="img"
                  style={{ width: "30%" }}
                />
              </div>
            </div>
          )}
          {related_training?.length > 0 && (
            <div className="card0">
              <h1 className="title-card">Related Training{oneToMany(related_training)}:</h1>
              {related_training.map((item: any) => (
                <div key={item.id}>
                  <span className="desc-card">{item.title}</span>
                  <img
                    src={item?.hero_image_url}
                    className="image-card0 "
                    alt="img"
                  />
                </div>
              ))}
            </div>
          )}

          <Grid item className="card pointer" sm={12}>
            <img src={creatorCard2} alt="icon" className="card-back-image" />
            <div className="card-content">
              <div className="card-details">
                <span className="title-card">Project Creator: </span>
                <span className="desc-card">{data.admin_data.data.attributes.first_name + " " + data.admin_data.data.attributes.last_name}</span>
              </div>
              <img
                src={
                  data.creator_card?.url
                }
                className="icon-card-one"
                alt="img"
                style={{ width: "30%" }}
              />
            </div>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default ProjectOverview;
