import React from "react";
import { Grid, Button } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
const ProjectDetailsMain = ({
  state,
  getButtonTitle,
  showPrevious,
  updateStepStatus,
  getPreviousStepId,
}: any) => {
  return (
    <Grid item sm={10} className="content">
      <Grid className="course-content">
        <div
          dangerouslySetInnerHTML={{
            __html:
              state?.projectDetails?.current_project_step_data?.data?.attributes
                ?.content || state.htmlContent,
          }}
          className="course-content-container"
        />
      </Grid>
      {state?.projectDetails?.project?.project_chapters && (
        <Grid item sm={12} className={showPrevious() ? "btn" : "btn1"}>
          {showPrevious() && (
            <Button
              className="change-btn1"
              startIcon={<KeyboardBackspaceIcon style={{ color: "#007DC6" }} />}
              onClick={() => getPreviousStepId()}
            >
              Previous step
            </Button>
          )}
          <Button
            id="change-btn2"
            variant="contained"
            className="change-btn2"
            endIcon={<DoneIcon style={{ color: "white" }} />}
            onClick={() => updateStepStatus()}
          >
            {getButtonTitle()}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectDetailsMain;
