import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getCommaSeperator, getCredits } from "../../../../components/src/NativeWebRouteWrapper/Utils";

export default function AccordionComponent({
  state,
  handleDeclineModal,
  handleApprove,
  item,
  getSumData,
}: any) {
  let totalCredits = getSumData(item?.requested_projects);
  return (
    <div className="accordion-container01">
      {state.tab === "archived" && (
        <div
          className={
            item?.status === "declined"
              ? "acc-notification-header-cancel"
              : "acc-notification-header-approveed"
          }
        >
          {item?.status === "declined" && (
            <span className="approved">❌ Declined</span>
          )}
          {item?.status === "approved" && (
            <span className="approved">✅ Approved</span>
          )}
        </div>
      )}
      <Accordion
        className={state.tab === "archived" ? "accordion1" : "accordion"}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="summory-content"
        >
          <div className="profile">
            <Avatar src={item?.teacher_image?.url} className="avatar-img" />{" "}
            <span className="name-text">&nbsp;&nbsp;{item?.teacher_name}</span>
          </div>
          <span className="desc alsc">{getCommaSeperator(totalCredits)} {getCredits(totalCredits)}</span>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} className="accordion-body">
            <Grid item container spacing={1} sm={12} className="pr0">
              <Divider className="divider-1" />
              {item?.requested_projects?.map((elements: any) => {
                return (
                  <Grid item sm={6} key={item}>
                    <div className="card">
                      <div className="header">
                        <span className="title">Pending Credit Request</span>
                        <span className="desc">
                          <Avatar src={elements.emoji} className="avatar" />
                          &nbsp;{elements?.title}
                        </span>
                      </div>
                      <div className="footer">
                        <span className="num">{getCommaSeperator(elements?.credits)}</span>
                        <span className="credit">{getCredits(elements?.credits)}</span>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
            {state.tab === "pending" && (
              <Grid item container className="btn-container" sm={12}>
                <Button
                  className="left-btn"
                  onClick={() =>
                    handleDeclineModal(item, "declined", totalCredits, null)
                  }
                >
                  Decline
                </Button>
                <Button
                  className="right-btn"
                  onClick={() => handleApprove(item, "approved", totalCredits)}
                >
                  Approve
                </Button>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
