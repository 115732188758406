import React from "react";
import { Grid, AccordionDetails } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";

const StepSTandards = ({ state, setStandardTab }: any) => {
  return (
    <Grid className="accordian-container-3" sm={12} item>
      <div className="accordian-container">
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span className="title-project-overview">Teaching Tools</span>
          </AccordionSummary>
          <AccordionDetails className="accordion-details steps-container">
            <Grid className="tab-content-btn-x" container>
              <Grid item container sm={12} className="tabs" spacing={2}>
                <Grid item sm={6}>
                  <div
                    id="checkout-btn1"
                    onClick={() => setStandardTab("0")}
                    className={
                      state.standardTab === "0" ? "activebtn pointer-cursor-on-0011" : "nonactivebtn pointer-cursor-on-0011"
                    }
                  >
                    <span className="checkout-txt0">Teaching tips</span>
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <div
                    id="checkout-btn2"
                    onClick={() => setStandardTab("1")}
                    className={
                      state.standardTab === "1" ? "activebtn pointer-cursor-on-0011" : "nonactivebtn pointer-cursor-on-0011"
                    }
                  >
                    <span className="purchase-txt0">Standards</span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="sta-tab-content">
              {state.standardTab === "1" &&
                state.projectDetails.current_project_step_data.data.attributes.standard_sets.map(
                  (item: any) => {
                    return (
                      <div className="standard-one-item">
                        <div className="cont-padd">
                          <span className="standard-title-x">
                            {item.attributes.title}
                          </span>
                        </div>
                        <div className="content-of-standards-tab">
                          {item.attributes.standards.map((item: any) => {
                            return (
                              <div className="details-card" key={item.id}>
                                <div className="left-content-container">
                                  <span className="standard-title-txt">
                                    {item.title}
                                  </span>
                                  <span className="standard-description-txt">
                                    {item.description}
                                  </span>
                                </div>
                                <a
                                  className="pointer"
                                  href={item.external_url}
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                  >
                                    <path
                                      d="M17.5 8.00001V3.00001M17.5 3.00001H12.5M17.5 3.00001L10 10.5M8.33333 3H6.5C5.09987 3 4.3998 3 3.86503 3.27248C3.39462 3.51217 3.01217 3.89462 2.77248 4.36503C2.5 4.8998 2.5 5.59987 2.5 7V14C2.5 15.4002 2.5 16.1002 2.77248 16.635C3.01217 17.1054 3.39462 17.4878 3.86503 17.7275C4.3998 18 5.09987 18 6.5 18H13.5C14.9002 18 15.6002 18 16.135 17.7275C16.6054 17.4878 16.9878 17.1054 17.2275 16.635C17.5 16.1002 17.5 15.4002 17.5 14V12.1667"
                                      stroke="#98A2B3"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                )}
              {state.standardTab === "0" && (
                <div className="standard-one-item">
                  <div className="tips-tool-title">
                    Teaching tips
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        state.projectDetails.current_project_step_data.data
                          .attributes.teaching_tips || "",
                    }}
                  />
                </div>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </Grid>
  );
};

export default StepSTandards;
