Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.getMySchoolDetailApiEndPoint = "/bx_block_activity_feed/credits";
exports.getMySchoolCreditHistoryApiEndPoint = "/bx_block_activity_feed/credit_activities?school_id=";
exports.getAllUserEndPoint = "account_block/accounts/show_invitation_mails";
exports.getUserDetailsApiContentType = "application/json";
exports.getUserDetailsAPiEndPoint = "account_block/accounts/";
exports.getUserDetailsAPiMethod = "GET";
exports.getDetialsTeacher = "bx_block_dashboard/dashboards"
exports.putUserDetailsAPiMethod = "PUT";
exports.deleteUserDetailsAPiMethod = "DELETE";
exports.upcomingDeliveriesApi = "bx_block_dashboard/dashboards/user_upcoming_deliveries"
exports.projectCompletionEndPoint = "bx_block_dashboard/dashboards/project_completion_notify"
exports.exampleAPiMethod = 'POST'
exports.validationApiContentType = "application/json"
// Customizable Area End