import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { AccordionDetails, Grid, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { completed, remaining, right, sideBarHeader } from "../assets";
import ProgressBarComponent from "./ProgressBarComponent";
import { getClassNameForStepOfProject, getClassNameForStepOfProjectPreview, getProjectProgressTitle, getTrimTitle } from "../../../../components/src/NativeWebRouteWrapper/Utils";

const ProjectsSidebar = ({
  state,
  getStepsDataWithTraining,
  navigation,
  toggleOverview,
  setContent,
}: any) => {
return (
    <>
      <Grid container className="sidebar1">
        <Grid item className="header">
          <img src={sideBarHeader} alt="back-img" className="header-image" />
          <img
            src={
              state.projectDetails?.project?.project_emoji ||
              state.projectDetails?.overview?.data?.attributes?.project_emoji.url
            }
            className="icon-sidebar-001"
            alt="title-icon"
          />
          <div
            className={
              state?.projectDetails?.project?.project_status_calculation === 100
                ? "header-title1"
                : "header-title"
            }
          >
            {state.previewMode == "preview" && (<span className="title templdkd">{getProjectProgressTitle(state)}</span>)}
            {state.previewMode !== "preview" && (
              <ProgressBarComponent
                value={
                  state?.projectDetails?.project?.project_status_calculation
                }
                title={getProjectProgressTitle(state)}
                className="progress-bar"
              />
              )}
          </div>
        </Grid>
        <div className="accordian-container-1">
          <Grid className="accordian-container">
            <div
              style={
                state.overview
                  ? {
                      padding: "10px 0px",
                      background: "#E8F6FE",
                      margin: "8px",
                      borderRadius: "8px",
                    }
                  : { padding: "18px 0px" }
              }
            >
              <span
                id="Open-overview-btn"
                className="title-project-overview pointer"
                onClick={() => toggleOverview(true)}
              >
                Overview
              </span>
            </div>
            <Accordion key={state?.projectDetails?.project?.project_id} defaultExpanded={true} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span className="title-project-overview">Steps</span>
              </AccordionSummary>
              {state.previewMode !== "preview" && (
                <AccordionDetails className="accordion-details steps-container">
                  {state?.projectDetails?.project?.project_chapters.map(
                    (item: any, index: number) => (
                      <div
                        className={getClassNameForStepOfProject(state, item.project_chapter_id)}
                        key={item.project_chapter_id}
                        id="course-step-btn"
                        onClick={() =>
                          getStepsDataWithTraining(item.project_chapter_id)
                        }
                      >
                        <img
                          src={
                            item.status === "not_started"
                              ? remaining
                              : completed
                          }
                          alt="iconn"
                        />
                        <span className="title">
                          &nbsp;&nbsp;{index + 1}:&nbsp;
                        </span>
                        <span className="title pointer">{getTrimTitle(40, item.title)}</span>
                      </div>
                    )
                  )}
                </AccordionDetails>
              )}
              {state.previewMode === "preview" && (
                <AccordionDetails className="accordion-details steps-container">
                  {state?.projectDetails?.steps?.data.map(
                    (item: any, index: number) => {
                      let { content, step_name } = item?.attributes;
                      return (
                        <div
                          id="set-content-btn"
                          className={getClassNameForStepOfProjectPreview(state, item.id)}
                          key={item.id}
                          onClick={() => setContent(content, item.id)}
                        >
                          <img src={remaining} alt="iconn" />
                          <span className="title">
                            &nbsp;&nbsp;{index + 1}:&nbsp;
                          </span>
                          <span className="title pointer">{getTrimTitle(40, step_name)}</span>
                        </div>
                      );
                    }
                  )}
                </AccordionDetails>
              )}
            </Accordion>
          </Grid>
        </div>
      </Grid>
      <div className="footer-card01">
        <div id="supportpage-btn" className="second" onClick={() => navigation.navigate("Support")}>
          <span className="title">
            Support&nbsp;
            <img src={right} alt="iconn" />
          </span>
        </div>
      </div>
    </>
  );
};

export default ProjectsSidebar;
