// Customizable Area Start
import React from "react";
import { Grid, Button } from "@material-ui/core";
export const configJSON = require("./config");
import RequestsWebController, { Props } from "./RequestsWebController";
import "../assets/index.scss";
import { folder, light, timer } from "./assets";
import AccordionComponent from "./components/AccordionComponent";
import DeclineRequestModal from "./components/DeclineRequestModal";
import NoCreditsModal from "./components/NoCreditsModal";
import AlertComponent from "./components/AlertComponent";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";

export default class Requests extends RequestsWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Grid container className="requests-container" spacing={5}>
        <Grid item container sm={12} className="header">
          <Grid item sm={6} className="left">
            <span className="req-title-txt">Requests</span>
            <div className="btn-container">
              <Button
                className={
                  this.state.tab === "pending" ? "pending-btn" : "archive-btn"
                }
                onClick={() => this.changeTab("pending")}
              >
                <img src={timer} alt="icon" />
                &nbsp;&nbsp;Pending
              </Button>
              <Button
                className={
                  this.state.tab === "archived" ? "pending-btn" : "archive-btn"
                }
                onClick={() => this.changeTab("archived")}
              >
                <img src={folder} alt="icon" />
                &nbsp;&nbsp;Archive
              </Button>
            </div>
          </Grid>
          <Grid item container sm={6} className="right-container">
            <div className="right">
              <div className="left">
                <span className="title">
                  {this.state?.storedData?.pending_requests_count}
                </span>
                {this.state?.storedData?.pending_requests_count === 1 || this.state?.storedData?.pending_requests_count === 0 ? <span className="desc">Pending request</span> : <span className="desc">Pending requests</span>}

              </div>
              <div>
                <img src={light} alt="icon" />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item container className="accordion-container" spacing={5}>
          {this.state?.storedData?.data?.map((item: any) => {
            return (
              <AccordionComponent
                getSumData={this.getSumData}
                state={this.state}
                key={item}
                handleDeclineModal={this.handleDeclineModal}
                handleApprove={this.handleApprove}
                item={item}
              />
            );
          })}
        </Grid>
        <DeclineRequestModal
          state={this.state}
          handleDeclineModal={this.handleDeclineModal}
          handleDeclineReq={this.handleDeclineReq}
        />
        <NoCreditsModal
          state={this.state}
          handleNocreditModal={this.handleNocreditModal}
          navigation={this.props.navigation}
        />
        {this.state.showAlert && (
          <AlertComponent
            title={this.state.errorMsg?.requested}
            description={this.state.errorMsg?.msg}
            success={false}
            handleClose={this.handleAlert}
            icon={this.state.errorMsg?.icon}
          />
        )}
        <LoaderComponent open={this.state.loading} />
      </Grid>
    );
  }
}
// Customizable Area End
