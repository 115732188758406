// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getUserDetails,
  getnusedcredits,
  handleTokenError,
  isTeacher,
} from "../../../components/src/NativeWebRouteWrapper/Utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  open: boolean;
  dialogOpen: boolean;
  leadMail: string;
  isDrawerOpen: boolean;
  openReclaimModal: boolean;
  openAddCreditModal: boolean;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  mySchoolDetails: any;
  teachersData: any;
  drawerDetails: any;
  teacherTransactionList: any[];
  page: number,
  perPage: number,
  pagination: any
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class MySchoolController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  submitApiCallId: any;
  getMySchoolDetailApiId: any;
  getPaymentsHistoryApiId: any;
  inviteTeacherApiId: any;
  creditAddRemoveApiid: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      open: false,
      dialogOpen: false,
      leadMail: "",
      isDrawerOpen: false,
      openReclaimModal: false,
      openAddCreditModal: false,
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      mySchoolDetails: {},
      teachersData: [],
      drawerDetails: {},
      teacherTransactionList: [],
      page: 1,
      perPage: 8,
      pagination: {}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    if (isTeacher()) {
      this.props.navigation.goBack()
    }
    this.setLeadMail();
    this.getMySchoolDetail();
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (responseJson) {
        this.setState({
          loading: false,
        });

        if (!responseJson.errors) {
          if (apiRequestCallId === this.getMySchoolDetailApiId) {
            let teachersData: any[] = [];

            let tempobj = responseJson.teacher_credit_details.map(
              (teacher: any) => {
                teachersData.push({
                  name: teacher.name || "",
                  earned: teacher.credit_data.data?.attributes?.reward || 0,
                  allocated:
                    teacher.credit_data.data?.attributes?.recieved || 0,
                  currentBalance:
                    teacher?.credit_data?.data?.attributes?.current_balance ||
                    0,
                  activated: teacher.activated || "",
                  teacherId: teacher.id || "",
                  image: teacher?.image?.url || "",
                  unused_earned_credits:getnusedcredits(teacher)
                });
                return teacher;
              }
            );
            let data = JSON.parse(localStorage.getItem("userInfo") || "");
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...data,
                school: {
                  ...data.school.data,
                  data: {
                    ...data.school.data, attributes: {
                      ...data.school.data.attributes, school_credits: {
                        data: [{ ...responseJson.school_credit_details.data }]
                      }
                    }
                  },
                },
              })
            );
            window.dispatchEvent(new Event("storage"));
            this.setState({
              mySchoolDetails: responseJson,
              teachersData: teachersData,
            });
          } else if (apiRequestCallId === this.getPaymentsHistoryApiId) {
            this.setState({
              pagination: responseJson.meta.pagination,
              teacherTransactionList: [...this.state.teacherTransactionList, ...responseJson.history],
            });
          } else if (apiRequestCallId === this.inviteTeacherApiId) {
            this.getMySchoolDetail();
            this.setState({
              showSnackbar: true,
              severity: "success",
              message:
                responseJson?.message || "Teacher is invited successfully",
            });
          } else if (apiRequestCallId === this.creditAddRemoveApiid) {
            this.getMySchoolDetail();
            this.setState({
              openReclaimModal: false,
              openAddCreditModal: false,
            });
            this.handleDrawer();
            this.setState({
              showSnackbar: true,
              severity: "success",
              message:
                responseJson?.response_message || "Credit updated successfully",
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  fetchMoreData = () => {
    this.setState({
      page: this.state.page + 1
    }, () => this.getPaymentHistoryApi(this.state.drawerDetails.teacherId))
  }

  getPaymentHistoryApi = (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPaymentsHistoryApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPaymentsHistoryApiEndPoint}${id}&page=${this.state.page}&per_page=${this.state.perPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getMySchoolDetail = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMySchoolDetailApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMySchoolDetailApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAddTeacher = (value: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };
    this.handleDialog();
    let datas = {
      data: {
        type: "email_account",
        attributes: {
          first_name: value.firstName,
          last_name: value.lastName,
          email: value.email,
          role_name: "Teacher",
          admin_create: true,
          email_invitation: true,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.inviteTeacherApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.inviteTeacherApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  setDrawerData = (value: any) => {
    this.setState({
      drawerDetails: value,
    });
    this.getPaymentHistoryApi(value.teacherId);
    return true;
  };

  setLeadMail = () => {
    let userMail = getUserDetails()?.email || "";
    let domain = userMail.replace(configJSON.regx, "");
    this.setState({ leadMail: domain });
    return true;
  };

  handleDialog = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
    return true;
  };

  handleDrawer = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
      page: 1,
      teacherTransactionList: []
    });
    return true;
  };

  handleReclaimModal = () => {
    this.setState({
      openReclaimModal: !this.state.openReclaimModal,
    });
    return true;
  };

  handleAddCreditModal = () => {
    this.setState({
      openAddCreditModal: !this.state.openAddCreditModal,
    });
    return true;
  };

  onClickReclaimCredit = (value: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    let datas = {
      data: {
        reason: value.reason,
      },
    };

    this.handleReclaimModal();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.creditAddRemoveApiid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCreditsApiEndPoint}teacher_id=${this.state.drawerDetails.teacherId}&value=${value.reclaimCredit}&credit_type=reclaimed`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAddCredit = (value: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    let datas = {
      data: {},
    };

    this.handleAddCreditModal();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.creditAddRemoveApiid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCreditsApiEndPoint}teacher_id=${this.state.drawerDetails.teacherId}&value=${value.AllocateCredit}&credit_type=allocated`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}
// Customizable Area End

// Customizable Area End
