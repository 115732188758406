
export const isUserloggedIn = () => {
  let x = localStorage.getItem("authToken");
  return !!x;
};
export const handleLogout = () => {
  localStorage.setItem("authToken", "");
  localStorage.setItem("userInfo", "");
  localStorage.setItem("showHeader", "false");
};

export const getUserDetails = () => {
  return JSON.parse(localStorage.getItem("userInfo") || "{}");
};

export const getAuthToken = () => {
  return localStorage.getItem("authToken");
};

export const isTeacher = () => {
  return ((getUserDetails()?.role?.id === 4) || getUserDetails()?.teacher_mode)
}

export const isLead = () => {
  return getUserDetails()?.role?.id === 3
}

export const showHeader = (pathName: string) => {
  return [
    "dashboard",
    "trainingdetails",
    "requests",
    "confirmtraining",
    "support",
    "classboxes",
    "project",
    "projectdetails"
  ].includes(pathName);
};

export const isValidUrl = (string: string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

export const shortString = (string: string, maxlength: number) => {
  let trimmedString = string.substr(0, maxlength);
  //re-trim if we are in the middle of a word
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
  return trimmedString;
};

export const changeLeadMode = (value: boolean) => {
  let userDetails = { ...getUserDetails(), teacher_mode: value };
  localStorage.setItem("userInfo", JSON.stringify(userDetails));
  window.dispatchEvent(new Event("storage"));
};

export const getLeadMode = () => {
  return getUserDetails()?.teacher_mode
}

export const canDrawerOpen = () =>
  ["trainingdetails", "projectdetails"].includes(
    window.location.pathname
      .substring(0)
      .split("/")[1]
      .toLowerCase()
  );

export const getTeacherName = (key: string) => {
  switch (key) {
    case "general_studies_teacher":
      return "General Studies Teacher";
    case "stem_teacher":
      return "STEM Teacher";
    case "administrator":
      return "Administrator";
    case "cte_teacher":
      return "CTE Teacher";
    default:
      return "";
  }
};

export const getTeacherNameReverse = (key: string) => {
  switch (key) {
    case "General Studies Teacher":
      return "general_studies_teacher";
    case "STEM Teacher":
      return "stem_teacher";
    case "Administrator":
      return "administrator";
    case "CTE Teacher":
      return "cte_teacher";
    default:
      return undefined;
  }
};

export const getClassNameCard = (status: string) => {
  switch (status) {
    case "past_due":
      return "notification-card border-red-card border-b-0";
    case "due_soon":
      return "notification-card border-orange-card border-b-0";
    case "":
      return "notification-card";
    default:
      return "notification-card";
  }
};
export let shouldShowCard = (item: any) => {
  return ["past_due", "due_soon", "completed"].includes(item?.order_details?.status);
}

export const getModalDescriptionForClassBoxesModal = (
  tab: string,
  cost?: string,
  dicountPrice?: number | string
) => {
  switch (tab) {
    case "keep":
      return `This Classbox is currently a Checkout. If you’d like to purchase this Classbox so you don’t ever have to return it, click Complete Purchase below. Converting this checkout to an purchase will cost ${dicountPrice} additional credits.`;
    case "Return":
      return `Now that you're done with this project, confirm that you'll be sending this Classbox back. Return instructions will be emailed to you.`;
    case "checkout":
      return `This Classbox is currently a Checkout. To extend the duration of your checkout by one month, click Complete Purchase below. You will use ${cost} additional credits.`;
    default:
      return "";
  }
};

export const getCheckoutCostData = (CheckoutCost: number, checkoutMonths: number, purchase_cost: number, quantity: number) => {
  let CheckoutPricePaid = CheckoutCost * checkoutMonths * quantity;
  let OwnItNowPrice = (purchase_cost * quantity) - (0.75 * CheckoutPricePaid);
return {
    dicountPrice: Math.round(OwnItNowPrice) > 0 ? Math.round(OwnItNowPrice) : 0,
    actualPrice: Math.round(purchase_cost) > 0 ?  Math.round(purchase_cost) : 0,
    saveCost: purchase_cost > 0 ? Math.round(purchase_cost - OwnItNowPrice) || 0 : 0,
    CreditsToOwnWithCheckoutCost: Math.round(CheckoutCost + OwnItNowPrice) > 0 ? Math.round(CheckoutCost + OwnItNowPrice) : 0
  }

}

export const getSumOfArray = (array: any[]) =>
  array.reduce((accumulator, currentValue) => accumulator + currentValue[2], 0);

export const getGradeCombo = (grades: string[]) => {
  let x: number[] = [];
  grades?.map((item: string) => {
    x.push(+item.split(" ")[1])
    return item;
  });
  return findContinuousNumbers(x)
}

function findContinuousNumbers(numbers: number[]) {
  numbers.sort((a, b) => a - b);
  let result = [];
  let start = numbers[0];
  let end = numbers[0];

  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] === end + 1) {
      end = numbers[i];
    } else {
      if (start === end) {
        result.push(getGrade(start));
      } else {
        result.push(`${getGrade(start)}-${getGrade(end)}`);
      }
      start = numbers[i];
      end = numbers[i];
    }
  }
  if (start === end) {
    result.push(getGrade(start));
  } else {
    result.push(`${getGrade(start)}-${getGrade(end)}`);
  }
  return result.join(', ');
}
export const getGrade = (key: string | number) => {
  switch (String(key)) {
    case "1":
      return "1st";
    case "2":
      return "2nd";
    case "3":
      return "3rd";
    case "4":
      return "4th";
    case "5":
      return "5th";
    case "6":
      return "6th";
    case "7":
      return "7th";
    case "8":
      return "8th";
    case "9":
      return "9th";
    case "10":
      return "10th";
    case "11":
      return "11th";
    case "12":
      return "12th";
    default:
      return `${key}th`;
  }
}



export const getCommaSeperator = (input: number | null | undefined | string) => {
  return input ? Math.round(+input).toLocaleString("en-US") : 0
}


export const getCredits = (input: number) => {
  if (input == 0 || input == 1) {
    return "Credit"
  } else if (input > 1) {
    return "Credits"
  }
}

export const getTeachers = (input: number) => {
  if (input == 0 || input == 1) {
    return "Teacher"
  } else if (input > 1) {
    return "Teachers"
  }
}


export const getTrimTitle = (to: number, value: string) => {
  return value?.length > to ? value?.substring(0, to) + "..." : value;
}

export const driftInitializer = () => {
  !function () {
    //@ts-ignore
    var t = window.driftt = window.drift = window.driftt || [];
    if (!t.init) {
      if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
      t.invoked = !0, t.methods = ["identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on"],
        //@ts-ignore  
        t.factory = function (e) {
          return function () {
            var n = Array.prototype.slice.call(arguments);
            return n.unshift(e), t.push(n), t;
          };
          //@ts-ignore
        }, t.methods.forEach(function (e) {
          t[e] = t.factory(e);
          //@ts-ignore
        }), t.load = function (t) {
          //@ts-ignore
          var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
          //@ts-ignore
          o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
          var i = document.getElementsByTagName("script")[0];
          //@ts-ignore
          i.parentNode.insertBefore(o, i);
        };
    }
  }();
}

export const getHeaderWelcomTitle = () => localStorage.getItem("showHeader") === "true" ? "Welcome," : "Welcome back,";
export const getStyleForDashBoard = () => localStorage.getItem("showHeader") === "true" ? {} : { marginTop: "20px" };

export const getPendingRequestTxt = (count: number | string) => {
  return +count === 1 || +count === 0 ? "Pending request" : "Pending requests";
}

export const renderOverview = (state: any) => {
  return state.overview &&
    (state.projectDetails?.overview?.data?.attributes ||
      state.projectDetails?.project?.overview?.data?.attributes)
}

export const getHrs = (input: any) => {
  if (input == 0 || input == 1) {
    return "hour"
  } else if (input > 1) {
    return "hours"
  }
}

export const getHours = (input: any) => {
  if (input == 0 || input == 1) {
    return "Hour"
  } else if (input > 1) {
    return "Hours"
  }
}

export const scrollToTop = () => {
  let access = document.getElementById("getBackxyz1297");
  // @ts-ignore
  access?.scrollIntoView({ behavior: 'auto' }, true);
};

export const reclaimedCredits = (spent: any, state: any, total_spent: number) => {
  let data = spent?.current_balance - (total_spent * state.months)
  if (data > 0) {
    return data
  } else {
    return 0
  }
}

export const getUnits = (input: any) => {
  if (input == 0 || input == 1 || input < 0) {
    return "Unit"
  } else if (input > 1) {
    return "Units"
  }
}

export const getSteps = (input: any) => {
  if (input == 0 || input == 1 || input < 0) {
    return "Step"
  } else if (input > 1) {
    return "Steps"
  }
}

export const getMinutes = (input: any) => {
  if (input == 0 || input == 1 || input < 0) {
    return "minute"
  } else if (input > 1) {
    return "minutes"
  }
}

export const getQuantity = (state: any) => {
  let qtyNum = state.quantityPurchase;
  let quatity: any = state.studentPerNumber.attributes.students_per_group / (5 * qtyNum);
  let ceilQuantity = Math.ceil(quatity)
  let floorQuantity = Math.floor(quatity)
  if (ceilQuantity == floorQuantity) {
    quatity = Math.round(quatity);
  } else {
    quatity = `${floorQuantity} or ${ceilQuantity}`;
  }
  return quatity
};


export const newReward = (user: any, reward: any, x?: true) => {
  return x ? (((user.account_credits?.data[0]?.attributes?.reward || 0) + (+reward))) : (((user.account_credits?.data[0]?.attributes?.current_balance || 0) + (+reward)))
}

export const getCommaStringArray = (item: string[]) => {
  return item?.join(", ");
}

export const setSelectedOptions = (state: any, dispatch: any, setSelectedOption: any) => {
  dispatch(setSelectedOption(state))
}

export const handleSelectAll = (selectedOptions: any, standardData: any, setStandardFilter: any, setSelectedOptions: any, selectAllStandard: any, dispatch: any, setSelectedOption: any) => {
  let allOptionsSelected = selectedOptions.length === standardData.length;
  if (allOptionsSelected) {
    standardData.map(async (option: any) => {
      await setStandardFilter({ id: option.id, title: option.title }, true)
      return option.id
    });
    selectAllStandard([], [])
    setSelectedOptions([], dispatch, setSelectedOption);
  } else {
    let ids: any = [];
    let titles: any = []
    const allValues = standardData.map((option: any) => {
      ids.push(option.id)
      titles.push(option.title)
      return option.id
    });
    selectAllStandard(ids, titles)
    setSelectedOptions(allValues, dispatch, setSelectedOption);
  }
};

export const handleOptionToggle = (x: any, selectedOptions: any, setStandardFilter: any, setSelectedOptions: any, dispatch: any, setSelectedOption: any) => () => {
  let value = x.id
  const currentIndex = selectedOptions.indexOf(value);
  const newSelectedOptions = [...selectedOptions];
  if (currentIndex === -1) {
    newSelectedOptions.push(value);
    setStandardFilter({ id: x.id, title: x.title }, false)
  } else {
    newSelectedOptions.splice(currentIndex, 1);
    setStandardFilter({ id: x.id, title: x.title }, true)
  }

  setSelectedOptions(newSelectedOptions, dispatch, setSelectedOption);
};


export const getTeacherData = () => {
  let leadTeacher = isTeacher()
    ? getUserDetails()?.account_credits?.data?.[0]?.attributes
      ?.current_balance
      ? getUserDetails()?.account_credits?.data?.[0]?.attributes
        ?.current_balance
      : 0
    : getUserDetails()?.school?.data?.attributes?.school_credits?.data?.[0]
      ?.attributes?.current_balance
      ? getUserDetails()?.school.data.attributes.school_credits.data[0]
        .attributes.current_balance
      : 0;
  return leadTeacher
}

export const customSort = (a: any, b: any) => {
  // Check if "account_added" is present and set to true for both objects
  const isAccountAddedA =
    a.attributes.account_added && a.attributes.account_added.added;
  const isAccountAddedB =
    b.attributes.account_added && b.attributes.account_added.added;

  // Sort based on "account_added" status
  if (isAccountAddedA && !isAccountAddedB) {
    return -1; // a comes first
  } else if (!isAccountAddedA && isAccountAddedB) {
    return 1; // b comes first
  } else {
    return 0; // leave the order unchanged
  }
}

export const getSymolColor = (history: any) => {
  if (history.creditable_type === "AccountBlock::Account") {
    switch (history.credit_type) {
      case "allocated":
      case "reward":
        return true;
      case "reclaimed":
      case "ordered":
        return false;
      default:
        return true;
    }
  } else {
    switch (history.credit_type) {
      case "spent":
      case "reclaimed":
        return false;
      case "unallocated":
      case "award":
      case "allocated":
      case "purchased":
        return true;
      default:
        return true;
    }
  }
}


export function isEmptyObj(obj: any) {
  for (let key in obj) {
    // if the loop has started, there is a property
    return false;
  }
  return true;
}
export const addClassRoot = () => {
  const element =
    document?.getElementsByClassName("css-view-1dbjc4n")[0] || {};
  element.className = "remove-style-root";
  const element1 = document.getElementsByTagName("body")[0] || {};
  element1.className = "remove-style-root1";
  const element2 = document.getElementById("root") || {};
  //@ts-ignore
  element2.className = "remove-style-root1";
}

export const removeClassRoot = () => {
  const element =
    document?.getElementsByClassName("css-view-1dbjc4n")[0] || {};
  element.className = "";
  const element1 = document.getElementsByTagName("body")[0] || {};
  //@ts-ignore
  element1.className = "";
  const element2 = document.getElementById("root") || {};
  //@ts-ignore
  element2.className = "";
}

export const handleTokenError = (Response: any) => {
  if (Response?.errors?.[0]?.token === "Token has Expired") {
    handleLogout();
    window.location.pathname = "/Login";
    localStorage.setItem("isFirstTeacher", JSON.stringify(false));
    localStorage.setItem("isFirstLead", JSON.stringify(false));
  }
}

export const monthDiff = (Range: [Date, Date]) => {
  return Range[1].getMonth() - Range[0].getMonth() +
    (12 * (Range[1].getFullYear() - Range[0].getFullYear()))
}

export const getClassboxPartsCheckOutPurchase = (item: any) => {
  let dataArray: any = [];
  if (item?.attributes?.checkout) {
    {
      item?.attributes?.classbox_parts?.data.map((element: any) => {
        if (element.attributes.checkout_part) {
          dataArray.push(element)
        }
      })
    }
  } else {
    {
      item?.attributes?.classbox_parts?.data.map((element: any) => {
        if (element.attributes.purchase_part) {
          dataArray.push(element)
        }
      })
    }
  }
  return dataArray
}

export const showClassbox = (key: string, x: any) => {
  let data = x?.attributes?.classbox_parts?.data || []
  if (+key === 0) {
    return data.filter((item: any) => item.attributes.checkout_part)
  } else {
    return data.filter((item: any) => item.attributes.purchase_part)
  }
}
export const getContainerOfFilter = () => document.getElementById("content-for-select")

export const oneToMany = (arr: any) => {
  if (arr.length > 1) {
    return "s"
  } else {
    return
  }
}

export const addS = (input: any) => {
  if (input == 0 || input == 1 || input < 0) {
    return ""
  } else if (input > 1) {
    return "s"
  }
}

export const getProjectProgressTitle = (state: any) => state?.projectDetails?.project?.title || state.projectDetails?.overview?.data?.attributes?.title

export const getClassNameForStep = (state: any, id: any) => {
  return state?.trainingDetails?.current_step_data?.chapter_id === id ? "course-step course-step-selected" : "course-step"
}
export const getClassNameForStepOfProject = (state: any, id: any) => {
  return ((state?.projectDetails?.current_project_step_data?.project_chapter_id === id) && (!state.overview)) ? "course-step course-step-selected" : "course-step"
}
export const getClassNameForStepOfProjectPreview = (state: any, id: any) => {
  return ((state?.selectedPrevieStepId === id) && (!state.overview)) ? "course-step course-step-selected" : "course-step"
}


export const getUserInfo = (user: any) => {
  return (user.account_credits?.data?.[0]?.attributes?.unused_earned_credits > 0 ? user.account_credits?.data?.[0]?.attributes?.unused_earned_credits : 0)
}

export const setLocalStorageCurrentBalance = (credits: any) => {
  localStorage.setItem(
    "userInfo",
    JSON.stringify({
      ...getUserDetails(),
      account_credits: {
        data: [
          {
            ...getUserDetails().account_credits?.data?.[0],
            attributes: {
              ...getUserDetails().account_credits?.data?.[0]?.attributes,
              current_balance: newReward(
                getUserDetails(),
                credits || 0
              ),
            },
          },
        ],
      },
    })
  );
}

export const getNavPathForVideoCard = () => isTeacher() ? "Classboxes" : "MySchool"

export const getInvitesTitle = (length: number) => length <= 0 ? "Next" : length === 1 ? "Send Invite" : "Send Invites";




export const filtereredDataOfNotification = (data: any) => data.filter((item: any) => (
  (item.order_details.status === "past_due" || item.order_details.status === "due_soon") ||
  (item.order_details.status === "completed" && item.order_details.all_projects_complete === true)
))

export const getTitleForbtn = (orderDetails: any, purchasedClassboxes: any,classBoxDetails:any ) => {
  let title: string = "Order Classbox";
  let p: any = [];
  purchasedClassboxes.forEach((item: any) => {
    if (item.order_details.status === "past_due") p.push(item.order_details.order_id)
  });
  if (orderDetails.status === "past_due"
    || orderDetails.status === "due_soon"
    || orderDetails.status === "completed"
    || (orderDetails.status === "completed" && orderDetails.all_projects_complete === true)) {
    title = "Manage Classbox";
    if (orderDetails.status === "past_due" && p.length > 0) {
      if (p.includes(orderDetails.order_id)) {
        title = "Manage Checkout";
      }
    }
  }
  if (orderDetails?.status === "credit_request_pending" || classBoxDetails?.attributes?.purchase_order_details?.status === "credit_request_pending") {
    title = "Request Pending"
  }
  
  return title;
}

export const getOrderDetailsWithStatus = (orderDetails: any, purchasedClassboxes: any) => {
  let title: boolean = false;
  let p: any = [];
  purchasedClassboxes.forEach((item: any) => {
    if (item.order_details.status === "past_due") p.push(item.order_details.order_id)
  });
  if (p.length > 0) {
    title = true
  }
  if ((orderDetails?.status === "completed") || orderDetails?.status === "due_soon") title = false;
  return title;
}

export const getBtnClass = (flag: boolean, orderDetails: any, purchasedClassboxes: any, classBoxDetails: any) => {
  let p: any = [];
  purchasedClassboxes.forEach((item: any) => {
    if (item.order_details.status === "past_due") p.push(item.order_details.order_id)
  });
  if (orderDetails?.status === "credit_request_pending") {
    return "btn"
  }
  if (classBoxDetails.attributes?.purchase_order_details?.status === "credit_request_pending") {
    return "btn"
  }
  if (p.includes(orderDetails.order_id)) {
    return "btn2";
  } else {
    return flag ? "btn"
      : "btn1";
  }
}
export const getBtnDisabled = (flag: boolean, orderDetails: any, purchasedClassboxes: any,classBoxDetails:any) => {
  let p: any = [];
  purchasedClassboxes.forEach((item: any) => {
    if (item.order_details.status === "past_due") p.push(item.order_details.order_id)
  });
  if (orderDetails?.status === "credit_request_pending" ||  classBoxDetails?.attributes?.purchase_order_details?.status === "credit_request_pending") {
    return true
  }
  if (p.includes(orderDetails.order_id)) {
    return false;
  } else {
    return flag;
  }
}

export const handleClassbox = (orderDetails: any, navigation: any, classBoxDetails: any, callback: any) => {

  if (orderDetails.status !== "" &&
    (orderDetails.status === "past_due"
      || orderDetails.status === "due_soon"
      || orderDetails.status === "completed"
    )) {

    callback({
      ...classBoxDetails?.attributes?.order_details,
      ...classBoxDetails?.attributes,
      ...classBoxDetails
    })
  } else {
    navigation.navigate("PurchaseClassBox", {
      ids: classBoxDetails.id,
    })
  }
}


export const getAllocatedData = (data: any) => {
  if (data > 0) {
    return data
  } else {
    return 0
  }
}
export const getDeliveryTitle = (length: number) => length > 1 ? "Upcoming Deliveries:" : "Upcoming Delivery:";

export const unCompletedArray = (data: any) => {
  let incompleted: any = []
  data.forEach((element: any) => {
    element.chapters.forEach((item: any) => {
      if (item.status !== "completed") {
        incompleted.push({ project: element, chapter: item });
      }
    })
  });
  return incompleted;
}

export const handleLastStep = (incompleted: any, getStepsDataWithTraining: any, credit: any, state: any, navigation: any) => {

  if (incompleted.length > 0) {
    getStepsDataWithTraining(
      incompleted[0].chapter.chapter_id
    );
  } else {
    let creditsTraining = credit;
    if (state.count === 1) {
      setLocalStorageCurrentBalance(credit);
      navigation.navigate("CompletedProject", {
        credits: creditsTraining || 0,
        title: state.trainingDetails.training_data.title,
        for: "training"
      })
    } else {
      navigation.navigate("Dashboard");
    }
  }
}
export const getTimesTitle = (value: any) => {
  if (value === "" || value == 1) {
    return "time"
  } else {
    return "times"
  }
}



export const setUserDetails = (responseJson: any,) => {
  let user = getUserDetails();
  localStorage.setItem(
    "userInfo",
    JSON.stringify({
      ...user,
      ...responseJson?.data?.attributes,
      id: responseJson?.data.id,
      teacher_mode: getLeadMode(),
    })
  );
  window.dispatchEvent(new Event("storage"));
}

export const handleUncompleteSteps = (state: any, navigation: any, getStepsDataWithTraining: any, x: boolean) => {

  let y: any = state.projectDetails?.project?.project_chapters.filter((item: any) => item.status === "not_started");
  if (y.length > 1) {
    getStepsDataWithTraining(
      y[0].project_chapter_id
    );
  } else {
    setLocalStorageCurrentBalance(state.projectDetails.project.overview.data.attributes.credit_award)
    x ? navigation.navigate("CompletedProject", {
      credits:
        state.projectDetails?.project?.overview?.data?.attributes
          ?.credit_award || 0,
      title: state.projectDetails?.project?.overview?.data?.attributes
        ?.title,
      for: "project"
    }) : navigation.navigate("Dashboard");
  }
}


export const getSchoolCurrBaklance = (state: any) => {
  return getCommaSeperator(
    (state.mySchoolDetails.school_credit_details?.data
      .attributes.current_balance +
      state.mySchoolDetails.school_credit_details?.data
        .attributes?.spent || 0) - state.mySchoolDetails.school_credit_details?.data
          .attributes?.claimed
  )
}


export const getTwitterURL = () => {
  const tweetText = "I just earned credits on Classbox!";
  const via = "Betaboxlearning";
  const hashtags = "stem";
  const tweetUrl = `https://twitter.com/share?text=${encodeURIComponent(
    tweetText
  )}&via=${via}&hashtags=${hashtags}`;
  return tweetUrl
}


export const getTitleFromItem = (items: any) => {
  return items?.attributes?.title
}

export const getEmojiFromItem = (items: any) => {
  return items?.attributes?.emoji?.url
}


export const checkOutCost = (item:any) => {
  return { ...item.attributes, ...item, ...item.attributes?.order_details }
}

export const managerNameFirstName = (name: any) => {
  return name.data?.attributes.first_name
}

export const managerNamelastName = (name: any) => {
  return name.data?.attributes.last_name
}

export const getnusedcredits = (teacher:any) => {
  return teacher.credit_data.data?.attributes?.unused_earned_credits || 0
}

export const addObj = (request:any,obj:any,CreditsToOwnWithCheckoutCost:any) => {
  if(request === "keep_requested"){
    Object.assign(obj,{keep_request_cost :  CreditsToOwnWithCheckoutCost})
  }

}

export const totalCostxq = (state:any) => {
return  state?.selectedCheckoutCard?.checkout_cost * state.selectedCheckoutCard.quantity
}

export const getExtendedCost = (state:any) => {
  return state?.selectedCheckoutCard?.checkout_cost * state.selectedCheckoutCard.quantity
}

export const dateFormatMM = (newDate:any) =>{
  const dateStr = newDate;
  const parts = dateStr.split('-');
  const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
  return formattedDate
}