import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { warnIcon } from "../assets";
import "../../assets/confirm-dialog.scss";

export default function DraggableDialog({
  onCancle,
  onOk,
  title,
  content,
  onOkText,
  onCancleText,
  open,
  handleClose,
  warningText,
}: any) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        className="dialog-container"
      >
        <DialogTitle className="title-text modal-title-bold">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className="content-text">{content}</DialogContentText>

          <div className="warn-container">
            <img src={warnIcon} alt="icon" />
            <span className="warn-text">{warningText}</span>
          </div>
        </DialogContent>
        <DialogActions className="btn-container-confirm-dialog">
          <Button onClick={onCancle} className="cancle-btn">
            {onCancleText}
          </Button>
          <Button onClick={onOk} className="ok-btn">
            {onOkText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
