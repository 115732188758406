// Customizable Area Start
import React from "react";
import { Button } from "@material-ui/core";
export const configJSON = require("./config");
import CompleteProjectPageController, {
  Props,
} from "./CompleteProjectPageController";
import "../assets/complete-taining.scss";
import { emailIcon, logo, rightWhite, twitterIcon } from "./assets";
import {
  getCommaSeperator,
  getCredits,
  getUserDetails
} from "../../../components/src/NativeWebRouteWrapper/Utils";
import { confirmation } from "../../ContentManagement/src/assets";

export default class CompleteProjectPage extends CompleteProjectPageController {

  creditsFromParam = this.props.navigation.getParam("credits");
  titleFromParam = this.props.navigation.getParam("title");
  for = this.props.navigation.getParam("for");

  render() {
    return (
      <div className="complete-taining-container">
        <div className="header">
          <img src={logo} alt="logo" />
        </div>
        <div className="card-container">
          <div className="card  card-success-of-classbox">
            <img src={confirmation} alt="img" className="img1-of-trainning"></img>
            {+this.creditsFromParam !== 0 && (
              <div className="header">
                <h1 className="title title-of-header-proj">
                  You’ve Earned {getCommaSeperator(this.creditsFromParam)}{" "}
                  {getCredits(this.creditsFromParam)}!
                </h1>
                <span className="desc desc-text-center">
                  For completing the{" "}
                  <span className="bold-title-0059rt">
                    {this.titleFromParam}
                  </span>{" "}
                  {this.for}.
                </span>
              </div>
            )}
            {+this.creditsFromParam === 0 && (
              <div className="header">
                <h1 className="title">Project Complete!</h1>
                <span className="desc desc-text-center">
                  You've finished teaching the{" "}
                  <span className="bold-title-0059rt">
                    {this.titleFromParam}
                  </span>{" "}
                  {this.for}.
                </span>
              </div>
            )}
            <div className="content">
              {+this.creditsFromParam !== 0 && (
                <div className="cardone">
                  <span>
                    <span className="title">
                      {getCommaSeperator(
                        getUserDetails().account_credits.data[0].attributes
                          .current_balance
                      )}
                    </span>
                    {" "}
                    <span className="sub-title">
                      {getCredits(
                        getUserDetails().account_credits.data[0].attributes
                          .current_balance
                      )}
                    </span>
                  </span>
                  <span className="desc vertical-div-span">
                    Total Credits earned to date. Nice work!
                  </span>
                </div>
              )}
              {+this.creditsFromParam !== 0 && (
                <span className="share-txt">Share:</span>
              )}
              <div className="btn-container">
                <a
                  href={`mailto:?body=Checkout Classbox.com where I just completed a proiect on ${this.titleFromParam}`}
                  target="_blank"
                  className="btn-social"
                >
                  <img src={emailIcon} alt="email" />
                  &nbsp;&nbsp;Email A Colleague
                </a>
                <a
                  href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                  className="twitter-share-button btn-social"
                  data-text="I just completed a project on Classbox! "
                  data-via="Betaboxlearning"
                  data-hashtags="stem"
                  data-show-count="false"
                  target="_blank"
                >
                  <img src={twitterIcon} alt="twitter" />
                  &nbsp;&nbsp;Twitter
                </a>
              </div>
              <Button
                className="dashboard-btn"
                onClick={() => this.props.navigation.navigate("Dashboard")}
              >
                Dashboard&nbsp;&nbsp;&nbsp;
                <img src={rightWhite} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// Customizable Area End
